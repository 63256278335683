import React from 'react';
import Helmet from 'react-helmet';

interface ComponentProps {
  title: string;
  description?: string;
  lang?: string;
  meta?: HTMLMetaElement[];
}

const Seo: React.FC<ComponentProps> = ({ description, lang, meta, title }) => (
  <Helmet
    htmlAttributes={{
      lang,
    }}
    title={title}
    meta={[
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },

      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ].concat(meta)}
  />
);

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default Seo;
